<template>
  <div class="mycls">
    <div class="mycls-in">
      <div class="cls-img">
          <b>{{lesson.tt}}</b>
          <p>{{lesson.num}}</p>
      </div>
      <div class="cls-dtl">
          <a><p>{{lesson.title}}</p></a>
          <p>{{lesson.desc}}</p>
          <div class="cls-hdl">
              Available
              <span class="dot"></span> {{ lesson.outline }}
              <span class="dot"></span> {{lesson.date}}                                       
            </div>
          </div>
          <div class="cls-ctr">
              <router-link class="btn btn-purple"
                v-if="lesson.open && page=='outline'" :to="{name:'outlineview', params: { chapter: lesson.id }}">
                Resume
              </router-link>
              <router-link class="btn btn-purple"
                v-if="lesson.open && page=='class'" :to="{name:'class', params: { outline: lesson.id }}">
                Resume
              </router-link>
              <a class="btn btn-purple"
                v-if="lesson.open && page=='project' && lesson.submit">
                Uploaded
              </a>
              <a class="btn btn-purple" v-on:click="selectFile"
                v-if="lesson.open && !state.uploading && page=='project' && !lesson.submit">
                Add
              </a>
              <div class="btn btn-purple" ref="per"
                v-if="lesson.open && page=='project' && !lesson.submit && state.uploading">
                Uploading...
              </div>
              <input type="file" style="display:none" v-on:change="uploadProject" ref="file"/>
              <a v-if="!lesson.open" v-on:click="showAlert(lesson.error)">
                <div class="btn btn-purple">
                  <svg fill="inherit" height="13px" width="13px" viewBox="0 0 330 330" xml:space="preserve" id="lck_svg">
                    <g id="XMLID_509_">
                      <path id="XMLID_510_" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85
                        S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15
                        s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
                        C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"/>
                    </g>
                  </svg>
                  Locked
                </div>
              </a>
          </div>
    </div>
  </div>
</template>
<script>
import { reactive, ref } from 'vue';
import videoupload from "../composables/videoupload.js";
import call from "../composables/call.js";
import { urls } from "../assets/config/urls";

  export default {
    name: 'OutlineView',
    props: {
      lesson: {
        type: Object,
        required: true
      },
      page:{
        type:String,
        default:"outline"
      }
    },
    setup(props){
      const file=ref("");
      const per=ref("");
      const state=reactive({uploading:false})
      function showAlert(msg){
        alert(msg)
      }

      function selectFile(){
        file.value.click()
      }

      function uploadProject(){
        state.uploading=true;
        videoupload({
          input: file,
          success:(res)=>{
            var fileName=res.fileName;
            var data={
              addProject:fileName,
              chapter_id:props.lesson.id
            }
            per.value.innerHTML="processing file";
            call({
              url:urls.class,
              data:data,
              success:(res)=>{
                state.uploading=false;
                if(res.status){
                  per.value.innerHTML="Upload completed";
                  window.location.reload()
                }else{
                  alert(res.msg);
                }
              }
            })
          },
          limit:120,
          error:(res) =>{
            state.uploading=false;
            alert(res.msg)
          },
          progress:(p)=>{
            per.value.innerHTML=p+"%";
          }
        })
      }
      console.log(props.page)

      return{
        showAlert,file,selectFile,state,uploadProject,per
      }
    }
  }
  </script>

<style scoped>
.mycls {border: 1px solid var(--line-color); padding: 10px; border-radius: 10px; margin: 10px 0;}
.mycls .mycls-in {display: flex;}
.mycls-in .cls-img {flex-basis: 15%;border-radius: 10px;overflow: hidden;display: flex;
    justify-content: center; align-items: center;flex-flow: column;}
.mycls-in .cls-dtl {flex-basis: 70%; padding: 10px;}
.cls-dtl a p {color: var(--primary-color); font-weight: bold; font-size: 16px;margin: 0;}
.cls-dtl p {margin: 0;}
.mycls-in .cls-ctr {display: flex; align-items: center;}
#lck_svg{margin-right:2px}
.cls-dtl .cls-hdl {
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: bold;
  margin-top: 5px;
}
.dot {
  width: 5px;
  height: 5px;
  background: var(--black);
  border-radius: 50%;
  display: inline-flex;
  margin: 0 5px;
}
.cls-img b {
  margin-bottom: -13px;
  font-size: 12px;
}
.cls-img p {
  margin: 0;
  font-size: 50px;
  font-weight: bold;
}
</style>