<template>
    <div class="qcard">
        <div class="question-card">
            <div class="img">
                <img :src="question.picture" class="user-picture">
            </div>
            <div class="txt">
                <div class="txt-bg">
                    <h3>{{ question.name }}</h3>
                    <p>{{ question.text }}</p>
                </div>
                <div class="txt-btm">
                    <p>{{question.date}}</p>
                    <p @click="answerQuestion" class="ans">answer</p>
                </div>
            </div>
        </div>
        <div class="nwques" v-if="!state.submitQuest && state.ansQues">
            <input type="text" ref="nwques" placeholder="Write your answer here"/>
            <div class="btn btn-purple" @click="askQues()">Submit</div>
        </div>
        <div class="nwquesldr" v-if="state.submitQuest">
            <span>Submitting answer</span>
            <img src="../assets/img/ajldr.gif" style="width:50px" />
        </div>
        <div class="anws" v-if="Object.keys(question.answers).length">
            <p class="anshd">Answers</p>
            <div class="question-card" v-for="answer in question.answers" :key="answer.id">
                <div class="img">
                    <img :src="answer.picture" class="user-picture">
                </div>
                <div class="txt">
                    <div class="txt-bg">
                        <h3>{{ answer.name }}</h3>
                        <p>{{ answer.text }}</p>
                    </div>
                    <div class="txt-btm">
                        <p>{{answer.date}}</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { reactive,ref } from 'vue';
import call from "../composables/call.js";
import { urls } from "../assets/config/urls";

export default {
    name: 'QuestionView',
    props: {
        question: {
            type: Object,
            required: true,
        },
    },
    setup(props){
        const state=reactive({submitQuest:false,ansQues:false})
        const nwques=ref("");
        
        function answerQuestion() {
            state.ansQues=true;
        }

        function askQues(){
            state.submitQuest=true;
            var quest=nwques.value.value
            call({
                url:urls.class,
                data:{answer:quest,addAnswer:props.question.id},
                success:(res)=>{
                state.submitQuest=false;
                if(res.status){
                    state.questions=res.msg
                }else{
                    alert(res.msg);
                }
                }
            })
        }

        return{
            answerQuestion,state,askQues,nwques
        }
    }
};
</script>

<style scoped>
.qcard{margin:20px 0}
.question-card{display:flex; margin: 10px 0;}
.img{width:40px; height:40px; border-radius: 50%; overflow:hidden;}
.txt-bg{background: #e7e7e7;margin-left: 10px; padding: 5px 10px; border-radius: 10px;}
.txt-bg h3{margin:0; font-size: 0.9rem;}
.txt-bg p{margin:0; font-size: 0.9rem;}
.txt-btm{padding: 5px 20px; display: flex; align-items: center;
    gap: 10px; font-size: 0.8rem;}
.txt-btm p{margin:0}
.ans{cursor:pointer; font-weight: bold;}

.nwques{margin-left:50px; display:flex; align-items:center; border: 2px solid var(--line-color);
    width: 80%; width: min(80%, 550px); border-radius: 40px; overflow: hidden;
    margin-bottom:20px}
  .nwques input{width: 100%; border: 0; outline: 0; padding: 9px;}
  .nwquesldr{display:flex; align-items: center; gap:10px; margin-left: 70px;}
  .anws{margin-left:50px}
  .anshd{margin:0; background:var(--line-color); padding: 10px;
    border-radius: 40px;}
</style>
  