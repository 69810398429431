<template>
  <div style="text-align:center; padding:20px" v-if="!state.loaded" >
    <img src="@/assets/img/ajldr.gif" style="width:100px"/>
  </div>
  <div v-if="state.loaded">
    <div v-for="lesson in state.uCls" :key="lesson.id">
      <LessonView :lesson="lesson" :projects="true"/>
    </div>
  </div>
</template>

<style>
.bnr {
  background-color: #e6e7e9; 
  display: flex; 
  border-radius: 20px; 
  align-items: center; 
  padding: 20px;
  align-content: space-around; 
  margin: 10px 0;
}
.bnr p {
  font-size: 20px; 
  font-weight: bold; 
  flex-basis: 70%; 
  font-family: sans-serif !important; 
  line-height: 1.42857143;
}
.bnr svg {
  flex-basis: 25%; 
  width: 150px; 
  height: 150px; 
  fill: #145d8d;
}
</style>

<script>
import LessonView from '@/components/LessonView.vue';
import { onMounted, reactive } from 'vue';
import call from "../composables/call.js";
import { urls } from "../assets/config/urls";

export default {
  name: 'ProjectsView',
  components: {
    LessonView
  },
  setup() {
    const state = reactive({
      uCls:[],loaded:false
    });

    onMounted(function() {
      call({
        url:urls.class,
        data:{getMyClass:""},
        success:(res)=>{
          state.loaded=true;
          if(res.status){
            state.uCls=res.msg
          }else{
            alert(res.msg);
          }
        }
      })
    });

    return {
      state
    };
  }
}
</script>
