<template>
  <div class="home">
    <div style="text-align:center; padding:20px" v-if="!state.loaded" >
      <img src="@/assets/img/ajldr.gif" style="width:100px"/>
    </div>
    <div class="nav" v-if="state.loaded">
      <router-link
          v-if="state.prev" :to="{name:'class', params: { outline: state.prev_id }}">
                Prev</router-link>
      <h4>Outline {{state.num}}</h4>
      <router-link :key="state.next_id"
          v-if="!state.isEnd" :to="{name:'class', params: { outline: state.next_id }}">
        Next</router-link>
    </div>
    <div class="hd" v-if="state.loaded">
      <h3 style="margin:0">{{state.title}}</h3>
      <p style="margin:0">{{ state.desc }}</p>
    </div>
    <div v-if="state.loaded">
      <VideoPlayer :src="state.video"/>
    </div>
    <div v-if="state.loaded">
      <h3>Questions & Answers</h3>
      <div class="nwques" v-if="!state.submitQuest">
        <input type="text" ref="nwques" placeholder="Ask your question here"/>
        <div class="btn btn-purple" @click="askQues()">Submit</div>
      </div>
      <div class="nwquesldr" v-if="state.submitQuest">
        <span>Submitting question</span>
        <img src="../assets/img/ajldr.gif" style="width:50px" />
      </div>
      <div class="question">
        <QuestionView v-for="question in state.questions" :key="question.id" :question="question" />
      </div>
    </div>
  </div>
</template>

<script>
import VideoPlayer from '@/components/VideoPlayer.vue';
import QuestionView from '@/components/QuestionView.vue';
import { onMounted, reactive,computed, ref } from 'vue';
import call from "../composables/call.js";
import { urls } from "../assets/config/urls";
import { useRoute } from 'vue-router';
import { watch } from 'vue'

export default {
  name: 'ClassOutline',
  components: {
    VideoPlayer,QuestionView
  },
  setup() {
    const state = reactive({loaded:false,title:"",desc:"",nextPage:"class",
      video:{},submitQuest:false,questions: [],num:"0",prev:false, prev_id:"",
      isEnd:true,next_id:""
    });
    const route = useRoute();
    const outline = computed(() => route.params.outline)
    const nwques=ref("");
    

    onMounted(function() {
      getPage();
    });

    function getPage(){
      call({
        url:urls.class,
        data:{getOutline:outline.value},
        success:(res)=>{
          state.loaded=true;
          if(res.status){
            state.video=res.msg.data
            state.title=res.msg.name
            state.desc=res.msg.desc
            state.num=res.msg.num
            state.questions=res.msg.ques
            state.prev=res.msg.prev
            state.prev_id=res.msg.prev_id
            state.isEnd=res.msg.isEnd
            state.next_id=res.msg.next_id
          }else{
            alert(res.msg);
          }
        }
      })
    }

    function askQues(){
      state.submitQuest=true;
      var quest=nwques.value.value
      call({
        url:urls.class,
        data:{addQuestion:quest,outline:outline.value},
        success:(res)=>{
          state.submitQuest=false;
          if(res.status){
            state.questions=res.msg
          }else{
            alert(res.msg);
          }
        }
      })
    }

    watch(
      () => route.params.outline,
      () => {
        state.loaded=false;
        getPage()
      },
      { immediate: false }
    );

    return {
      state,outline,nwques,askQues,getPage,route
    };
  }
  
}
</script>
<style scoped>
.nav{display:flex; align-items:center}
.nav a{padding:10px 20px; cursor:pointer; background: var(--primary-color-dark);
  color: white; border-radius: 20px;}
.nav h4{text-align: center; flex: 1; margin: 10px 20px; font-size: 30px;}
.hd{background: var(--primary-color-dark);
  padding: 10px 20px;
  border-radius: 20px;
  color: var(--white-color);}
.hd h3{margin-bottom: 10px;}
.nwques{display:flex; align-items:center; border: 2px solid var(--line-color);
  width: 80%; width: min(80%, 600px); border-radius: 40px; overflow: hidden;}
.nwques input{width: 100%; border: 0; outline: 0; padding: 9px;}
.nwquesldr{display:flex; align-items: center; gap:10px}
</style>
