export const LoginModule={
    namespaced:true,

    state:{
        login:{
            status:false,
            auth_code:""
        }
    },

    mutations:{
        SET_LOGIN(state, login){
            state.login=login;
        }
    },

    actions:{
        setLogin({commit}, login){
            commit('SET_LOGIN',login);
        }
    },
};