<template>
  <div class="video-container">
    <img 
      v-if="!videoPlayed" 
      :src="src.thumbnail" 
      class="thumbnail" 
      alt="Video Thumbnail"
    />
    <div class="playwrap"
      v-if="!videoPlayed"
      @click="playVideo" >
      <div class="playic">
        <svg fill="#ffffff" height="24px" width="24px" version="1.1"
            viewBox="0 0 460.114 460.114" xml:space="preserve">
          <g>
            <g>
              <path d="M393.538,203.629L102.557,5.543c-9.793-6.666-22.468-7.372-32.94-1.832c-10.472,5.538-17.022,16.413-17.022,28.26v396.173
                c0,11.846,6.55,22.721,17.022,28.26c10.471,5.539,23.147,4.834,32.94-1.832l290.981-198.087
                c8.746-5.954,13.98-15.848,13.98-26.428C407.519,219.477,402.285,209.582,393.538,203.629z"/>
            </g>
          </g>
        </svg>
      </div>
    </div>
    <video 
      v-show="videoPlayed" 
      :src="src.src" 
      controls 
      ref="video"
      class="video-player"
    />
  </div>
</template>

<script>
import { ref } from 'vue';

export default {
  name: 'VideoPlayer',
  props: {
    src: {
      type: Object,
      required: true,
    }
  },
  setup(props) {
    const videoPlayed = ref(false);
    const video = ref(null);
    console.clear()
    console.log(props.src)

    const playVideo = () => {
      videoPlayed.value = true;
      video.value.play();
    };

    return {
      videoPlayed,
      video,
      playVideo,
    };
  },
};
</script>

<style scoped>
.video-container {
  position: relative;
  width: 100%;
  margin: 20px 0;
}

.thumbnail {
  width: 100%;
  cursor: pointer;
}

.video-player {
  width: 100%;
}
.playwrap{    position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;cursor:pointer;
  justify-content: center;}

.playic{width: 72px; 
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 3px solid #fff;
  background: rgba(0, 0, 0, .6);}
</style>
