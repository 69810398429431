import { createRouter, createWebHistory } from 'vue-router'
import ClassDashboard from '../views/ClassDashboard.vue'
import ClassOutline from '../views/ClassOutline.vue'
import ClassView from '../views/ClassView.vue'
import ProjectsView from '../views/ProjectsView.vue'
import ProjectDashboard from '../views/ProjectDashboard.vue'

const routes = [
  {
    path: '/',
    name: 'home',component: () => import(/* webpackChunkName: "about" */ '../views/ClassesView.vue')
  },
  {
    path: '/classes',
    name: 'classes',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ClassesView.vue')
  },
  {
    path: '/classdashboard/:class_id',
    name: 'classdash',
    component: ClassDashboard
  },
  {
    path: '/outlineview/:chapter',
    name: 'outlineview',
    component: ClassOutline
  },
  {
    path: '/class/:outline',
    name: 'class',
    component: ClassView
  },
  {
    path: '/projects',
    name: 'projects',
    component: ProjectsView
  },
  {
    path: '/projectdashboard/:class_id',
    name: 'projectdash',
    component: ProjectDashboard
  },
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

export default router
