<template>
  <DashboardView>
    <router-view/>
  </DashboardView>
</template>

<script>
// import TopNavigation from "@/components/TopNavigation";
import {useStore} from "vuex";
import {computed, reactive} from "vue";
import { urls } from "@/assets/config/urls";
import DashboardView from "@/components/DashboardView";
export default {
  name: 'App',
  components:{
    DashboardView
  },
  setup(){
    const store=useStore();
    const user=computed(()=>store.state.User.user);
    const state=reactive({login:false});

    function verifySess(){
      const update = {verifySess: "verifySess"};
      const options = {method: 'POST',headers: {'Content-Type': 'application/json'},credentials:"include", body: JSON.stringify(update)};
      fetch(urls.dashboard, options).then(data => {
          if (!data.ok) {
              throw Error(data);
          }
          return data.json();
      }).then(res => {
        if(!res.session){
          // window.location=urls.login;
        }else if(res.redirect){
          // window.location=res.redirect;
          return;
        }else{
          var r=res.data.msg
          var user={
            name:r.name,
            username:r.usrid
          }
          var login={
              status:true,
              auth_code:r.auth_code
            }
          store.dispatch("Login/setLogin",login);
          store.dispatch("User/setUser",user);
          state.login=true;
        }
      }).catch(()=> {
        // window.location=urls.login;
      });
    }
    return {
      verifySess,
      user,
      state
    }
  },
  beforeCreate: function(){
    this.verifySess();
  }
  // components:{TopNavigation}
}
</script>
<style></style>
<style>
@import url("./assets/css/fonts.css");
@import url("./assets/css/main.css");
</style>
