<template>
    <div class="mycls">
        <div class="mycls-in">
            <div class="cls-img">
                <img :src="lesson.banner">
            </div>
            <div class="cls-dtl">
                <a><p>{{lesson.name}}</p></a>
                <p>{{lesson.desc}}</p>
                <!--<div class="cls-hdl">-->
                <!--    <span class="dot"></span> 1 Month-->
                <!--    <span class="dot"></span> 3 Lessons-->
                <!--    <span class="dot"></span> Video Only-->
                <!--    <span class="dot"></span> The Vix Academy-->
                <!--</div>-->
            </div>
            <div class="cls-ctr">
                <router-link class="btn btn-purple" v-if="lesson.open && !projects" :to="{name:'classdash', params: { class_id: lesson.id }}">
                  Resume
                </router-link>
                <router-link class="btn btn-purple" v-if="lesson.open && projects" :to="{name:'projectdash', params: { class_id: lesson.id }}">
                  Projects
                </router-link>
                <a v-if="!lesson.open" onclick="javascript:Alert.render('Please complete previous chapter to unlock this chapter')">
                  <div class="btn btn-purple">
                    <svg fill="inherit" height="13px" width="13px" viewBox="0 0 330 330" xml:space="preserve" id="lck_svg">
                      <g id="XMLID_509_">
                        <path id="XMLID_510_" d="M65,330h200c8.284,0,15-6.716,15-15V145c0-8.284-6.716-15-15-15h-15V85c0-46.869-38.131-85-85-85
                          S80,38.131,80,85v45H65c-8.284,0-15,6.716-15,15v170C50,323.284,56.716,330,65,330z M180,234.986V255c0,8.284-6.716,15-15,15
                          s-15-6.716-15-15v-20.014c-6.068-4.565-10-11.824-10-19.986c0-13.785,11.215-25,25-25s25,11.215,25,25
                          C190,223.162,186.068,230.421,180,234.986z M110,85c0-30.327,24.673-55,55-55s55,24.673,55,55v45H110V85z"/>
                      </g>
                    </svg>
                    Locked
                  </div>
              </a>
            </div>
        </div>
    </div>
</template>
<script>
  export default {
    name: 'LessonView',
    props: {
      lesson: {
        type: Object,
        required: true
      },
      projects:{
        type:Boolean,
        default:false
      }
    }
  }
  </script>

<style scoped>
.mycls {border: 1px solid var(--line-color); padding: 10px; border-radius: 10px; margin: 10px 0;}
.mycls .mycls-in {display: flex;}
.mycls-in .cls-img {flex-basis: 15%;border-radius: 10px;overflow: hidden;display: flex;
    justify-content: center; align-items: center;flex-flow: column;}
.mycls-in .cls-dtl {flex-basis: 70%; padding: 10px;}
.cls-dtl a p {color: var(--primary-color); font-weight: bold; font-size: 16px;margin: 0;}
.cls-dtl p {margin: 0;}
.mycls-in .cls-ctr {display: flex; align-items: center;}
#lck_svg{margin-right:2px}
</style>