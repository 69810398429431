<template>
  <div class="home">
    <div style="text-align:center; padding:20px" v-if="!state.loaded" >
      <img src="@/assets/img/ajldr.gif" style="width:100px"/>
    </div>
    <div v-if="state.loaded">
      <div v-for="lesson in state.uCls" :key="lesson.id">
        <OutlineView :lesson="lesson" :page="state.nextPage" />
      </div>
    </div>
  </div>
</template>

<script>
import OutlineView from '@/components/OutlineView.vue';
import { onMounted, reactive,computed } from 'vue';
import call from "../composables/call.js";
import { urls } from "../assets/config/urls";
import { useRoute } from 'vue-router';

export default {
  name: 'ProjectDashboard',
  components: {
    OutlineView
  },
  setup() {
    const state = reactive({loaded:false,title:"",desc:"",nextPage:"project",
      uCls:[]
    });
    const route = useRoute();
    const class_id = computed(() => route.params.class_id)
    

    onMounted(function() {
      call({
        url:urls.class,
        data:{getProjects:class_id.value},
        success:(res)=>{
          state.loaded=true;
          if(res.status){
            state.uCls=res.msg.data
            state.title=res.msg.name
            state.desc=res.msg.desc
          }else{
            alert(res.msg);
          }
        }
      })
    });

    return {
      state,class_id
    };
  }
  
}
</script>
