import { urls } from "../assets/config/urls";
export default function videoupload({input,success=()=>{},progress=()=>{},error=()=>{},limit}){
    var Error="",
    err="",
    isUploadable=true,
    uploadFileName="",
    index=0,
    chunk=3*1024*1024;
    //make sure a valid file has been selected
    if(input.value.value === ""){
        //if there's no value then fire the onError event
        err={
            type:"User error",
            msg:"Please select a file"
        }
        error(err);
        return;
    }
    limit=limit*1024*1024;
    if(!limit){
        limit=70*1024*1024;
    }
    var src=input.value.files[0],
    fileName=src.name;
    if(src.size> limit){
        err={
            type:"User error",
            msg:"File size too big, maximum upload size is 70mb"
        }
        error(err);
        return;
    }
    check();
    //do upload
    function doUpload(){
        if(!isUploadable){
            if(error !=""){
                error("System error");
                return;
            }
            return;
        }
        var chunks=Math.ceil(src.size/chunk);
        var start=index;
        var current=Math.ceil(index/chunk);
        var cut=chunk+(current*chunk);
        var formdata=new FormData()
        var thisfile=src.slice(start,cut)
        var ajax=new XMLHttpRequest();
        ajax.upload.addEventListener("progress",progressHandler, false);
        ajax.addEventListener("load", completeHandler, false);
        ajax.addEventListener("error", errorHandler, false);
        ajax.open('POST',urls.videoupload);
        formdata.append("gfile",thisfile)
        formdata.append("filename", fileName);
        // formdata.append("author_code",login.value.auth_code);
        if(chunks == current){
            var com={
                type:"Completed",
                fileName:uploadFileName,
                msg:"Upload Completed"
            }
            success(com);
            return;
        }
        ajax.send(formdata);

        function completeHandler(event){
            var get=event.target.responseText.split('|');
            if(get[0].replace(/\s/g, "") != "good"){
                var err={
                    type:"upload error",
                    msg:event.target.responseText
                }
                error(err)
                return;
            }else{
                index=get[1];
                if(src.size>index){
                    doUpload();
                }else{
                    var com={
                        type:"Completed",
                        fileName:uploadFileName,
                        msg:"Upload Completed"
                    }
                    success(com);
                }
            }
        }

        function progressHandler(e){
            e.index=index;
            e.filesize=src.size;
            var totalLoaded=parseInt(e.index)+e.loaded;
            var p=(totalLoaded/e.filesize);
            var percent=Math.ceil(p*100);
            if(percent>100){percent=100}
            progress(percent);
        }

        function errorHandler(event){
            var err={
                type:"upload error",
                msg:event.target.responseText
            }
            error(err);
            return;
        }
    }
    //check the file
    function check(){
        var formData=new FormData()
        // formData.append("author_code",login.value.auth_code);
        formData.append("checkFile",fileName);
        var req = new Request(urls.videoupload,{method: 'POST',headers: {}, body: formData});

        fetch(req).then(res => {
            if (!res.ok) {
                console.log("error")
                throw Error(res);
            }
            return res.text();
        }).then(res => {
            var response=res.split('|');
            if(response[0].replace(/\s/g, "")=="true"){
                index=response[1];
                if(index==src.size){
                    var com={
                        type:"Completed",
                        fileName:uploadFileName,
                        msg:"Upload Completed"
                    }
                    success(com);
                    return;
                }else if(index> src.size){
                    err={
                        type:"File error",
                        msg:"File has been damaged"
                    }
                    error(err);
                    return;
                }
                uploadFileName=response[2];
                isUploadable=true;
            }else{
                var err={
                    type:"File error",
                    msg:res
                }
                error(err);
                return;
            }
            doUpload();
        }).catch(()=> {
            error("Network error, please ensure you have internet connection");
        });
    }
}