
import { urls } from "@/assets/config/urls";
export default function call({url,data,success=() => {},error=() =>{},aborter}){
    let req=null;
    if(aborter && aborter instanceof AbortController){
        req = new Request(url,{method: 'POST',headers: {'Content-Type': 'application/json'},credentials:"include", body: JSON.stringify(data),signal: aborter.signal});
    }else{
        req = new Request(url,{method: 'POST',headers: {'Content-Type': 'application/json'},credentials:"include", body: JSON.stringify(data)});
    }
    fetch(req).then(res => {
        if (!res.ok) {
            console.log("error")
            throw Error(res);
        }
        return res.json();
    }).then(res => {
        if(!res.session){
            window.location=urls.login;
        }else if(res.redirect){
            window.location=res.redirect;
            return;
        }else{
            var result=res.data;
            success(result);
        }
    }).catch(()=> {
        error("Network error, please ensure you have internet connection");
    });
}